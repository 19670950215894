.container {
  display: flex;
  padding: 0px;
  gap: 0px;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}


.image {
  display: flex;
  width: 100%;
  height: 80%;
  object-fit: contain;
  object-position: center;
  z-index: 0;
  overflow: visible;
}

.body-text {
  font-family: 'Work Sans', sans-serif;
  font-size: large;
  font-weight: 800;
  color: #FFFFFF;
}
